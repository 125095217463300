import { useLastRenderValue } from 'shared/hooks/usePrevious'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { Modal } from 'antd'

export default function(profile) {
  const { codingLead, curriculumDeveloper, role, roles = {} } = profile
  const prevRoles = useLastRenderValue({
    codingLead,
    curriculumDeveloper,
    role,
    roles
  })
  const currentRoles = { codingLead, curriculumDeveloper, role, roles }

  useEffect(() => {
    if (!profile.isEmpty && hasRolesChanged(prevRoles, currentRoles)) {
      Modal.info({
        title: 'Permissions Updated',
        content:
          'Your permissions have been updated. Please refresh the page to activate them.',
        okText: 'Refresh',
        okButtonProps: { icon: 'reload' },
        keyboard: false,
        centered: true,
        width: 450,
        onOk: () => document.location.reload(true)
      })
    }
  }, [prevRoles, currentRoles])
}

/**
 * @function hasRolesChanged
 * Checks whether or not a user's permissions have been updated
 * @returns boolean
 */
function hasRolesChanged (previousRoles, currentRoles) {
  if (
    previousRoles === undefined ||
    previousRoles.curriculumDeveloper === undefined ||
    previousRoles.codingLead === undefined ||
    previousRoles.role === undefined
  ) {
    return false
  }
  if (previousRoles.curriculumDeveloper !== currentRoles.curriculumDeveloper) {
    return true
  }
  if (previousRoles.codingLead !== currentRoles.codingLead) {
    return true
  }
  if (previousRoles.role !== currentRoles.role) return true
  if (!isEqual(previousRoles.roles, currentRoles.roles)) return true

  return false
}
