const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const {
  displayName,
  firebaseRef,
  timestamp,
  email,
  uuid,
  url
} = require('../utils')

const principal = Schema()
  .prop('name', Schema('string'))
  .prop('email', email)

const cleverSchool = Schema()
  .prop('id', uuid)
  .prop('district', uuid)
  .prop('name', Schema('string'))
  .prop('created', timestamp)
  .prop('last_modified', timestamp)
  .prop('sis_id', Schema('string'))
  .prop('school_number', Schema('string'))
  .prop('state_id', Schema('string'))
  .prop('nces_id', Schema('string'))
  .prop('mdr_number', Schema('string'))
  .prop('low_grade', Schema('string'))
  .prop('high_grade', Schema('string'))
  .prop(
    'location',
    Schema()
      .prop('zip', Schema('string'))
      .prop('address', Schema('string'))
      .prop('city', Schema('string'))
      .prop('state', Schema('string'))
  )

const School = Schema()
  .prop('displayName', displayName)
  .prop('providers', Schema().prop('clever', cleverSchool.schema))
  .prop('district', firebaseRef)
  .prop('id', Schema('string'))
  .required(['displayName'])
  .others(false)

const update = Schema()
  .prop('school', firebaseRef)
  .prop('displayName', displayName)
  .prop('principal', principal)
  .prop('passwordEnabled', Schema('boolean'))
  .required(['displayName', 'principal'], 'missing_required_field')
  .others(false, 'invalid_keys')

const create = Schema()
  .prop('imageUrl', url)
  .prop('displayName', displayName)
  .prop('district', firebaseRef)
  .prop('principal', principal)
  .required(['displayName', 'district'], 'missing_required_field')
  .others(false, 'invalid_keys')

const addTeacher = Schema()
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .required(['school', 'teacher'], 'missing_required_field')
  .others(false, 'invalid_keys')

const removeTeacher = Schema()
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .required(['school', 'teacher'], 'missing_required_field')
  .others(false, 'invalid_keys')

const archive = Schema().prop('school', firebaseRef)

const schoolAndOnlySchool = Schema()
  .prop('school', firebaseRef)
  .required(['school'], 'missing_required_field')
  .others(false, 'invalid_keys')

const schoolsByDistrict = Schema()
  .prop(/^[a-zA-Z0-9-_]{6,}$/, Schema('array').items(firebaseRef))

const getSelectedSchoolsGitEngagement = Schema()
  .prop('schools', schoolsByDistrict)
  .required(['schools'], 'missing_required_field')
  .others(false, 'invalid_keys')

exports.default = School
exports.removeTeacher = validate(removeTeacher)
exports.addTeacher = validate(addTeacher)
exports.archive = validate(archive)
exports.create = validate(create)
exports.update = validate(update)
exports.getProficiencyData = validate(schoolAndOnlySchool)
exports.getTeacherEngagement = validate(schoolAndOnlySchool)
exports.getGitEngagement = validate(schoolAndOnlySchool)
exports.getOverviewData = validate(schoolAndOnlySchool)
exports.getSelectedSchoolsGitEngagement = validate(getSelectedSchoolsGitEngagement)

/** @todo remove when getProficiencyOverview deprecated */
exports.getProficiencyOverview = validate(schoolAndOnlySchool)
