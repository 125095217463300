import { adminRoles, gradeValueToId } from 'shared/utils/data'
import { classViewsEvents } from 'analytics/classViews'
import { createAnalyticsThunk } from 'shared/analytics'
import modalContainer from 'components/modalContainer'
import { Menu, Icon, Button, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { lessonEvents } from 'analytics/lessons'
import getConfig from 'shared/config/getConfig'
import { alphaCompare } from 'shared/utils'
import UserDropdown from './UserDropdown'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import MenuTitle from './MenuTitle'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './Sidebar.less'

const teacherRoles = [
  'teacher',
  'admin',
  'districtAdmin',
  'ninedotsTeacher',
  'ninedotsAdmin'
]
const { adminUrl } = getConfig()

function Sidebar(props) {
  const { classes = [], pathname, profile, logout, modal, uid } = props
  const { displayName, role, curriculumDeveloper, codingLead } = profile
  const isStudent = role === 'student'
  const isNinedotsTeacher =
    role === 'ninedotsTeacher' || role === 'ninedotsAdmin'
  const isTeacher = teacherRoles.includes(role)
  const isAdmin = adminRoles.includes(role)

  const teacherClasses = classesByRole(classes, uid, 'teachers')
  const studentClasses = classesByRole(classes, uid, 'students')
  const isTeacherAndStudent = teacherClasses.length && studentClasses.length

  const { openKeys, toggleResources } = useOpenKeys(pathname, classes)

  return (
    <>
      <UserDropdown
        isCurriculumDeveloper={curriculumDeveloper}
        isTeacher={isTeacher}
        displayName={displayName}
        isStudent={isStudent}
        logout={logout}
      />
      <Menu
        selectedKeys={getSelected(pathname, teacherClasses)}
        openKeys={openKeys}
        style={{ borderRight: 0 }}
        mode='inline'
        theme='dark'
      >
        {!isStudent && (
          // array of menu items used instead of React.Fragment as antd expects direct descendants
          [
            <Menu.SubMenu
              className='submenu'
              key='/resources'
              title={
                <Row type='flex' justify='space-between' align='middle' onClick={toggleResources}>
                  <Col
                    className='ellipsis'
                    style={{ flex: 1, paddingRight: 25 }}
                  >
                    <Icon type='info-circle' />
                    Resources
                  </Col>
                </Row>
              }
            >
              <Menu.Item key='/resources/help-videos'>
                <Link to='/resources/help-videos'>Help Videos</Link>
              </Menu.Item>
              <Menu.Item key='/resources/faqs'>
                <Link to='/resources/faqs'>Frequently Asked Questions</Link>
              </Menu.Item>
            </Menu.SubMenu>,
            <Menu.Item key='/courses'>
              <Link to='/courses'>
                <Icon type='appstore-o' />
                Course Catalog
              </Link>
            </Menu.Item>
          ]
        )}
        {curriculumDeveloper && (
          <Menu.Item key='/library'>
            <Link to='/library'>
              <Icon type='form' style={{ marginRight: 10 }} />
              Create
            </Link>
          </Menu.Item>
        )}
        {(isAdmin || codingLead) && (
          <Menu.Item key='admin'>
            <a href={adminUrl} rel='noopener noreferrer' target='_blank'>
              <Icon type='export' />
              Admin
            </a>
          </Menu.Item>
        )}
        {teacherClasses.length && <Menu.Divider />}
        {Object.values(teacherClasses).map(
          cls => (
            <Menu.SubMenu
              className={cx({
                'hide-arrow': true,
                'single-class': teacherClasses.length === 1
              })}
              title={<MenuTitle class={cls} isTeacher />}
              key={cls.id}
            >
              <Menu.Item key={`/class/${cls.id}/dashboard`}>
                <Link to={`/class/${cls.id}/dashboard`}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key={`/class/${cls.id}/lessons`}>
                <Link
                  to={`/class/${cls.id}/lessons/active`}
                  onClick={() => {
                    props.trackAssignedLessonView('Sidebar')
                  }}
                >
                  Lessons
                </Link>
              </Menu.Item>
              <Menu.Item key={`/class/${cls.id}/students`}>
                <Link to={`/class/${cls.id}/students`}>Students</Link>
              </Menu.Item>
              <Menu.Item key={`/class/${cls.id}/progress`}>
                <Link
                  to={`/class/${cls.id}/progress`}
                  onClick={props.trackProgressView}
                >
                  Progress
                </Link>
              </Menu.Item>
              {gradeValueToId(cls.grade) > 0 && (
                <Menu.Item key={`/class/${cls.id}/insights`}>
                  <Link
                    to={`/class/${cls.id}/insights`}
                    onClick={props.trackInsightsView}
                  >
                    Insights
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )
        )}
        {isTeacherAndStudent && <Menu.Divider />}
        {Object.values(studentClasses).map(
          cls => (
            <Menu.Item key={`/class/${cls.id}/lessons`}>
              <Link to={`/class/${cls.id}/lessons`} className='ellipsis'>
                <Icon type={isTeacherAndStudent ? 'rocket' : 'book'} />
                {cls.displayName}
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
      {isTeacher && !isNinedotsTeacher && (
        <div style={{ padding: '12px 24px' }}>
          <Button
            ghost
            className='join-class-button'
            onClick={modal.showModal({
              name: 'classModal',
              school: profile.school
            })}
          >
            <Icon type='plus' />
            Join Class
          </Button>
        </div>
      )}
      <div style={{ flex: '1' }} />
      <div style={{ padding: 10, textAlign: 'center' }}>
        <span style={{ fontSize: 20 }}>9 DOTS</span>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  pathname: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired
}

export default compose(
  modalContainer,
  connect(null, {
    trackAssignedLessonView: createAnalyticsThunk(
      lessonEvents.assignedLessonView
    ),
    trackProgressView: createAnalyticsThunk(
      classViewsEvents.progressView,
      null
    ),
    trackInsightsView: createAnalyticsThunk(classViewsEvents.insightsView, null)
  })
)(Sidebar)

export { Sidebar }

function useOpenKeys(pathname, classes = []) {
  const openKeys = []

  const [_, firstPathPart, maybeClass] = pathname.split('/')

  const [lastClass, setLastClass] = useState('')

  if (classes.length === 1) {
    openKeys.push(classes[0].id)
  } else if (firstPathPart === 'class') {
    openKeys.push(maybeClass)
  } else if (lastClass) {
    openKeys.push(lastClass)
  }

  // save open class for easy return
  useEffect(() => {
    if (openKeys[0] && openKeys[0] !== lastClass) {
      setLastClass(openKeys[0])
    }
  }, [openKeys[0]])

  const [showResources, setShowResources] = useState(firstPathPart === 'resources')

  if (showResources) {
    openKeys.push('/resources')
  }

  // only close resource menu if not on a resource page
  const toggleResources = () => {
    if (!showResources) {
      setShowResources(true)
    } else if (firstPathPart !== 'resources') {
      setShowResources(false)
    }
  }

  return { openKeys, toggleResources }
}

function getSelected(pathname, classes = []) {
  const classPaths = classes.map(({ id }) => `/class/${id}/lessons`)
  const resourcePaths = ['/help-videos', '/faqs']
    .map(path => '/resources' + path)
  const loosePaths = ['/courses', '/library', ...classPaths, ...resourcePaths]

  const loosePath = loosePaths.find(path => pathname.startsWith(path))
  return [loosePath || pathname]
}

const classesByRole = (classes, uid, role = 'teachers') =>
  classes
    .filter(cls => (cls[role] || []).includes(uid))
    .sort((a, b) => alphaCompare(a, b, 'displayName'))
