const firestore = window.firebase.firestore()

// Classes
export const classesBySchool = ({ id, district }) =>
  firestore
    .collection('classes')
    .where('district', '==', district)
    .where('school', '==', id)
    .where('archived', '==', false)

export const classRef = classId => firestore.doc(`classes/${classId}`)

// District
export const districtRef = districtId =>
  firestore.doc(`districts/${districtId}`)
export const districtsRef = () => firestore.collection('/districts')

// Schools
export const schoolsByDistrict = districtId =>
  firestore
    .collection('schools')
    .where('district', '==', districtId)
    .where('archived', '==', false)

export const schoolRef = schoolId => firestore.doc(`schools/${schoolId}`)

// Users
export const usersBySchool = ({ id, district }) =>
  firestore
    .collection('users')
    .where('district', '==', district)
    .where('schools', 'array-contains', id)
    .where('archived', '==', false)

export const userRef = userId => firestore.doc(`users/${userId}`)

export const classesForTeacher = userId =>
  firestore
    .collection('classes')
    .where('teachers', 'array-contains', userId)
    .where('archived', '==', false)

export const classesForUser = userId =>
  firestore
    .collection('classes')
    .where('members', 'array-contains', userId)
    .where('archived', '==', false)

export const districtAdmins = district =>
  firestore
    .collection('users')
    .where('district', '==', district)
    .where('role', '==', 'districtAdmin')

// Modules
export const moduleRef = id => (id ? firestore.doc(`modules/${id}`) : undefined)

// Progress Refs (lesson, user/student, activity)
const lpCollection = firestore.collection('lessonProgress_v2022')

export const lessonProgressRef = (classId, moduleId, lessonId) =>
  firestore.doc(`classes/${classId}/${moduleId}-progress/${lessonId}`)

export const lessonProgressUserQuery = (
  moduleId,
  lessonId,
  userId,
  schoolYearId
) =>
  lpCollection
    .where('moduleId', '==', moduleId)
    .where('lessonId', '==', lessonId)
    .where('userId', '==', userId)
    .where('schoolYearId', '==', schoolYearId)
export const lessonProgressUsersRef = (classId, moduleId, lessonId) =>
  lpCollection
    .where('classIds', 'array-contains', classId)
    .where('moduleId', '==', moduleId)
    .where('lessonId', '==', lessonId)

export const draftsRef = mod => firestore.collection(`/modules/${mod}/drafts`)
export const coursesRef = () =>
  firestore.collection('/courses').orderBy('displayIndex', 'asc')

// Platform
export const platformRef = () =>
  firestore.collection('platform').where('archived', '==', false)