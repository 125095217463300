import getProp from '@f/get-prop'
import { memoize } from 'utils'
import pick from '@f/pick'
import map from '@f/map'

/**
 * @function progressByStudent
 * @todo make this memoizeable to reduce rerenders
 * @description aggregate student progress data
 * @param {object} state redux state
 * @param {object} lesson lesson data
 * @param {object} students students in class
 * @param {object} mod module data
 * @param {string} classId classId
 * @returns {object<{student: Object, active: string, progress: array}>}
 */
const progressByStudent = (
  state,
  lesson,
  students,
  assignedModule,
  classId
) => {
  const progress =
    state.firestore.data[`${assignedModule}-${(lesson || {}).id}`] || {}
  return students.map(key => ({
    student: state.firestore.data[`students-${key}`],
    active: (progress[key] || {}).active,
    progress: lesson
      ? lesson.activities.map(activity => {
        const activityProgress =
            getProp(`${key}.activities.${activity.id}`, progress) || {}
        return {
          ...activity,
          ...activityProgress,
          lesson: lesson.id,
          assignedModule: assignedModule,
          class: classId
        }
      })
      : undefined
  }))
}

/**
 * @function classBySchools
 * @description memoized selector to get classes grouped by school
 * @param {Object} state
 * @param {Array} schools
 * @param {Array} allClasses
 * @returns {Object}
 */
const classBySchools = memoize((state = {}, schools, allClasses) => {
  return map(
    (classes, key) => ({
      ...state[key],
      classes
    }),
    getClasses(allClasses, schools)
  )
})

const schoolClasses = (state, school) => {
  return state.firestore.ordered[`${school}-classes`] || []
}

/**
 * @function moduleSelector
 * @description memoized function to map module firestore data on to an array of module ids
 * @param {Object} state
 * @param {Array} mods array of module ids
 * @returns {Array} module data with ids
 */

const moduleSelector = memoize((state = {}, mods) =>
  mods.map(m => state[m] && { ...state[m], id: m })
)

const assignedCourses = (state, courseIds) =>
  courseIds.map(
    c => state.firestore.data[c] && { ...state.firestore.data[c], id: c }
  )

const students = memoize((state = {}, students = []) =>
  students.map(student => {
    if (state[student]) {
      return {
        id: student,
        ...state[student]
      }
    }
  })
)
const classes = (state, id) => state.firestore.ordered[`classes-${id}`]
const teacherClasses = state => state.firestore.ordered.teacherClasses
const allClasses = state => state.firestore.ordered[`allClasses`]
const studentAssignment = (state, id, lesson) =>
  getProp(`lessonProgress-${lesson}-${id}`, state.firestore.data)
const myModules = state => state.firestore.ordered.myModules
const courses = state => state.firestore.ordered.courses
const draft = (state, id) =>
  getProp(`modules.${id}.drafts`, state.firestore.data)
const module = (state, id) => getProp(id, state.firestore.data)
const school = (state, id) => {
  if (state.firestore.data[id] === undefined) return undefined
  if (state.firestore.data[id] === null) return null
  return { id, ...state.firestore.data[id] }
}
const profile = state => state.firebase.profile
const uid = state => state.firebase.auth.uid
const district = state => state.firebase.profile.district

export const injectId = memoize((val, id) => (val ? { ...val, id } : undefined))
export const schools = memoize((profile = {}) => {
  return profile.schools
})
export const roles = memoize((profile = {}) => profile.roles)
export const role = memoize((profile = {}) => profile.role)
export const profileMeta = memoize((profile = {}) => {
  return pick(
    [
      'curriculumDeveloper',
      'displayName',
      'codingLead',
      'district',
      'isEmpty',
      'school',
      'email',
      'name',
      'role'
    ],
    profile
  )
})

export {
  progressByStudent,
  studentAssignment,
  assignedCourses,
  classBySchools,
  moduleSelector,
  teacherClasses,
  schoolClasses,
  allClasses,
  myModules,
  students,
  district,
  courses,
  classes,
  profile,
  module,
  school,
  draft,
  uid
}

function getClasses (classes, schools) {
  return schools.reduce(
    (acc, school) => ({
      ...acc,
      [school]: (classes || []).filter(cls => cls.school === school)
    }),
    {}
  )
}
