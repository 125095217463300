/**
 * @file Schema for motivation responses.
 * @author Julius Diaz Panoriñgan
 */

const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const { firebaseRef, url, uuid, grade } = require('../utils')

const recordResponse = Schema()
  // .prop('questionId', Schema('number').multiple(1.0)) // 0
  .prop('response', Schema('number').multiple(1.0))
  .prop('followUpFun', Schema('boolean'))
  .prop('followUpEasy', Schema('boolean'))
  .prop('followUpChallenging', Schema('boolean'))
  .prop('followUpGood', Schema('boolean'))
  .prop('followUpOther', Schema('boolean'))
  .prop('askedTime', Schema('number'))
  .prop('answeredTime', Schema('number'))
  .prop('class', firebaseRef)
  .prop('lesson', uuid)
  .prop('module', firebaseRef)
  .prop('student', firebaseRef)
  .prop('url', Schema(url))
  .prop('course', firebaseRef) // still possible?
  /*
  // not really relevant?
  // Motivation questions are bound to modules or lessons, not activities
  .prop('project', uuid) // basically just a type of task?!
  .prop('task', uuid)
  .prop('integration', Schema('number').multiple(1.0))
  */
  .required([
    'class',
    'lesson',
    'module',
    // 'questionId', // actually, maybe, force this to 0, and have a different endpoint for surveys
    'response',
    'student'
  ])

const getGraphData = Schema()
  .prop('class', firebaseRef)
  .required(['class'])

const getRecentResponses = Schema()
  .prop('class', firebaseRef)
  .prop('numLessons', Schema('number').multiple(1.0))
  .required(['class'])

const getLessonResponses = Schema()
  .prop('class', firebaseRef)
  .prop('lesson', uuid)
  .required(['class', 'lesson'])

const getSurveyQuestions = Schema()
  .prop('grade', grade)
  .prop('type', Schema('string'))
  .required(['grade', 'type'])

const individualSurveyResponse = Schema()
  .prop('questionId', Schema('number').multiple(1.0))
  .prop('answerId', Schema('number').multiple(1.0))
  .required(['questionId', 'answerId'])

const recordSurveyResponse = Schema()
  .prop('grade', grade)
  .prop('type', Schema('string'))
  .prop('responses', Schema('array').items(individualSurveyResponse.schema))
  .prop('askedTime', Schema('number'))
  .prop('answeredTime', Schema('number'))
  .prop('class', firebaseRef)
  .prop('lesson', uuid)
  .prop('module', firebaseRef)
  .prop('student', firebaseRef)
  // .prop('url', Schema(url))
  .prop('course', firebaseRef)
  .required([
    'class',
    'grade',
    'lesson',
    'module',
    'responses',
    'student',
    'type'
  ])

const getAdminInterestData = Schema()
  .prop('school', firebaseRef)
  .required(['school'])

const getSchoolSurveyResponses = Schema()
  .prop('school', firebaseRef)
  .required(['school'])

exports.recordResponse = validate(recordResponse)
exports.getGraphData = validate(getGraphData)
exports.getRecentResponses = validate(getRecentResponses)
exports.getLessonResponses = validate(getLessonResponses)
exports.getSurveyQuestions = validate(getSurveyQuestions)
exports.recordSurveyResponse = validate(recordSurveyResponse)
exports.getAdminInterestData = validate(getAdminInterestData)
exports.getSchoolSurveyResponses = validate(getSchoolSurveyResponses)
